<template>
  <div class="index">
    <header class="hero">
      <div class="container-lg wrapper">
        <div class="content">
          <h1 class="display-4">The best way to improve reading</h1>
          <p class="lead">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam alias voluptatum expedita dolore esse totam.</p>
          <b-button variant="primary" class="px-4">Register</b-button>
        </div>
        <div class="undraw">
          <b-img alt="wakaru hero" src="/img/undraw_reading_time_gvg0.svg" />
        </div>
      </div>
      <svg class="curve_path" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319">
        <path fill-opacity="1" d="M0,0L48,26.7C96,53,192,107,288,144C384,181,480,203,576,213.3C672,224,768,224,864,197.3C960,171,1056,117,1152,101.3C1248,85,1344,107,1392,117.3L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
    </header>

    <main class="main_content">
      <div class="container-lg content_wrapper">

        <div class="container my-3 text-center">
          <h2>{{ $t('welcome', ['Wakaru.Org']) }}</h2>
          <p class="lead">Empowering African children with reading skills!</p>
        </div>

        <div class="search_form">
            <b-input-group>
              <b-form-input placeholder="Search for books" type="search"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" type="submit" size="sm"><SearchIcon /></b-button>
              </b-input-group-append>
            </b-input-group>
            <p><small>Enter ISBN, keywords, title, author or publisher name.</small></p>
        </div>

        <BookCategoryList />

        <b-carousel class="carousel" :interval="10000" controls indicators>
          <b-carousel-slide img-src="https://picsum.photos/1200/500/?image=119">
            <div class="caption_wrapper">
              <h1 class="title">Read and Learn</h1>
              <hr class="divider" />
              <p>
                Cultivate good reading habits in simple steps.<br />Read ➔ Take a test ➔ Earn points!
              </p>
              <b-button variant="primary">Join Us</b-button>
            </div>
          </b-carousel-slide>
          <b-carousel-slide img-src="https://picsum.photos/1200/500/?image=20">
            <div class="caption_wrapper">
              <h1 class="title">For all ages</h1>
              <hr class="divider" />
              <p>
                From elementary to senior secondary schools
              </p>
              <b-button variant="primary">Register</b-button>
            </div>
          </b-carousel-slide>
          <b-carousel-slide img-src="https://picsum.photos/1200/500/?image=390">
            <div class="caption_wrapper">
              <h1 class="title">Track progress</h1>
              <hr class="divider" />
              <p>
                Teachers and parents can monitor progress of their students with ease.
              </p>
              <b-button variant="primary">Learn More</b-button>
            </div>
          </b-carousel-slide>
        </b-carousel>

      </div>
    </main>
  </div>
</template>

<script>
import SearchIcon from 'mdi-vue/Magnify';
import BookCategoryList from '@/views/public/book/BookCategoryList.vue';

export default {
  name: 'index',
  components: {
    SearchIcon, BookCategoryList,
  },
};

</script>

<style lang="stylus">

$carousel_xl_max_height = 60vh

.site_main
  position relative
  .index
    position relative

    .hero
      position relative
      padding 0
      color var(--color_on_primary)
      background-color var(--color_primary)
      .wrapper
        position relative
        padding 2rem
        padding-bottom 5rem
        margin-bottom -5rem
        .content
          z-index 2
          position relative
          padding-top 1rem
          @media $mq_lg
            padding-top 2rem
          p
            color var(--color_on_secondary)
        .undraw
          z-index 1
          position absolute
          bottom 0
          right 0
          width 80%
          max-width 25rem
      .curve_path
        margin 0
        path
          fill var(--color_background)

    .main_content
      z-index 2
      position relative
      color var(--color_on_background)
      background-color var(--color_background)
      .content_wrapper
        @media $mq_lg
          margin-top -5rem
        @media $mq_xl
          margin-top -7rem
        @media $mq_wd
          margin-top -10rem
        @media $mq_xwd
          margin-top -13rem

        .search_form
          text-align center
          @media $mq_lg
            padding 0 5%
        .card-deck
          .card
            flex 1 30%
            margin-bottom 1rem
        .carousel
          text-shadow: 2px 2px 2px var(--color_on_background);
          @media $mq_xl
            max-height $carousel_xl_max_height
            overflow hidden
          .carousel-item::after
            @media $mq_sm
              content ""
              position absolute
              top 0
              bottom 0
              left 0
              right 0
              display block
              background-color rgba(0, 0, 0, 0.2)
          .carousel-caption
            display none
            @media $mq_sm
              top 0
              bottom 0
              padding 0
              display flex
              flex-direction column
              justify-content center
            @media $mq_md
              padding-bottom 3rem
              justify-content flex-end
              text-align left
            @media $mq_xl
              max-height $carousel_xl_max_height
            .caption_wrapper
              width 100%
              .title
                font-size var(--font_size_index_caption_title)
              .divider
                width 75%
                max-width 20rem
                margin 0.5rem auto
                background-color currentColor
                @media $mq_md
                  margin 0.5rem 0
            .carousel-indicators
              margin-bottom 0.5rem
              @media $mq_md
                margin-bottom 1rem

</style>
